<div class="d-flex buttons">
  <div class="left-content">
    <div class="export-btn">
      <button class="btn cstm-btn-primary" (click)="downloadCSV()">
        <i class="fa fa-download mr-2"></i>{{"orderDetail.csv"|cxTranslate}}
      </button>
      <button class="btn cstm-btn-light" (click)="downloadPDF()">
        <i class="fa fa-download mr-2"></i>{{"orderDetail.pdf"|cxTranslate}}
      </button>
    </div>
  </div>
</div>